<template>
  <div class="app-box">
    <div class="visit-max-title">拜访人员</div>
    <x-form-box>
      <x-form-input v-if="xhrData.staffVo.name" label="姓名" disable v-model="xhrData.staffVo.name"></x-form-input>
      <x-form-input v-if="xhrData.staffVo.telephone" label="联系电话" disable v-model="xhrData.staffVo.telephone"></x-form-input>
      <x-form-input v-if="xhrData.staffVo.positon" label="职务" disable v-model="xhrData.staffVo.positon"></x-form-input>
    </x-form-box>
    <div class="visit-max-title">拜访概要</div>
    <x-form-box>
      <div class="client-box">
        <label>客户地址</label>
        <p v-if="customerData">{{customerData.address}}</p>
      </div>
      <x-form-position v-if="xhrData.beginAddress" label="开始" :isRed="xhrData.scopeIsOkBegin" :address="xhrData.beginAddress" :dates="xhrData.beginTime"></x-form-position>
      <x-form-position v-if="xhrData.endAddress&&!xhrData.forgetEnd" label="结束" :isRed="xhrData.scopeIsOkEnd" :address="xhrData.endAddress" :dates="xhrData.endTime"></x-form-position>
      <x-form-position v-if="xhrData.forgetEnd" label="结束" isRed address="忘打卡"></x-form-position>
    </x-form-box>
    <div v-if="xhrData.visitPurpose||xhrData.otherPurpose||xhrData.visitDescripiton||xhrData.visitImgList" class="visit-max-title">拜访详情</div>
    <x-form-box>
      <x-form-select v-show="xhrData.visitPurpose" label="拜访目的" disable :slots="VisitPurposeEnum" :bindValue="xhrData.visitPurpose"></x-form-select>
      <x-form-input v-if="xhrData.visitPurpose==20&&xhrData.liveness" label="活跃度" disable v-model="xhrData.liveness"></x-form-input>
      <!-- <x-form-input v-if="xhrData.otherPurpose" label="其他拜访目的" disable v-model="xhrData.otherPurpose"></x-form-input> -->
      <x-form-textarea v-if="xhrData.visitDescripiton" label="具体描述" disable v-model="xhrData.visitDescripiton"></x-form-textarea>
      <x-form-images v-show="xhrData.visitImgList" disable :imgList="xhrData.visitImgList"></x-form-images>
    </x-form-box>
    <!-- xhrData.shopOpen|| -->
    <div
      v-if="xhrData.medicineInfoVoList||xhrData.businessChanceDesc||xhrData.businessChancheImgList||xhrData.otherChances&&xhrData.otherChances.length>0||xhrData.facilityDtoList&&xhrData.facilityDtoList.length>0"
      class="visit-max-title">业务机会</div>
    <x-form-box>
      <!-- <x-form-select v-show="xhrData.shopOpen" label="商城开户" disable :slots="shopOpenEnum" :bindValue="xhrData.shopOpen"></x-form-select> -->
      <x-form-drugs-list v-show="xhrData.medicineInfoVoList" label="药品采购" disable :list="xhrData.medicineInfoVoList"></x-form-drugs-list>
      <x-form-device-list v-show="xhrData.facilityDtoList&&xhrData.facilityDtoList.length>0" label="设备采购" disable :list="xhrData.facilityDtoList"></x-form-device-list>
      <x-form-tag v-show="xhrData.otherChances&&xhrData.otherChances.length>0" label="其他机会" disable :slots="VisitOtherChanceEnum" :tagList="xhrData.otherChances"></x-form-tag>
      <x-form-textarea v-if="xhrData.businessChanceDesc" label="具体描述" disable v-model="xhrData.businessChanceDesc"></x-form-textarea>
      <x-form-images v-show="xhrData.businessChancheImgList" disable :imgList="xhrData.businessChancheImgList"></x-form-images>
    </x-form-box>
    <div class="split-box"></div>
    <i v-if="!isLeader&&!isEdit" class="edit-icon" @click="editEvent"></i>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formPosition from "@/components/formControl/formPosition";
import formTextArea from "@/components/formControl/formTextArea";
import formDrugsList from "@/components/formControl/formDrugsList";
import formDeviceList from "@/components/formControl/formDeviceList";
import formTag from "@/components/formControl/formTag";
import formImages from "@/components/formControl/formImages";
import { getVisit } from "@/api/visitRecord";
import { getCustomer } from "@/api/customerCenter";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      isEdit: this.$route.params.isEdit,
      xhrData: {
        staffVo: {}
      },
      customerData: null,
      shopOpenEnum: [{
        flex: 1,
        values: [{
          code: 1,
          name: "是"
        }, {
          code: 0,
          name: "否"
        }],
        textAlign: "center",
        defaultIndex: 0
      }],
      VisitPurposeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      VisitOtherChanceEnum: []
    };
  },
  created () {
    document.title = "";
    document.title = "拜访详情";
    this.setEnum();
  },
  methods: {
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      Object.keys(configJson.VisitPurposeEnum).forEach(key => {
        this.VisitPurposeEnum[0].values.push({
          code: key,
          name: configJson.VisitPurposeEnum[key]
        });
      });
      Object.keys(configJson.VisitOtherChanceEnum).forEach(key => {
        this.VisitOtherChanceEnum.push({
          code: key,
          name: configJson.VisitOtherChanceEnum[key],
          check: false
        });
      });
      this.getData();
    },
    getData () {
      getVisit({
        id: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.scopeIsOkBegin = parseInt(this.xhrData.scopeIsOkBegin);
        this.xhrData.scopeIsOkEnd = parseInt(this.xhrData.scopeIsOkEnd);
        this.getCustomerData();
      });
    },
    getCustomerData () {
      getCustomer({
        customerId: this.xhrData.customerId
      }).then(xhr => {
        this.customerData = xhr.data;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/visitRecord/edit/edit/${this.$route.params.id}`
      });
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formPosition.name]: formPosition,
    [formTextArea.name]: formTextArea,
    [formDrugsList.name]: formDrugsList,
    [formDeviceList.name]: formDeviceList,
    [formTag.name]: formTag,
    [formImages.name]: formImages
  }
};
</script>

<style scoped>
.client-box {
  display: flex;
  margin-bottom: 0.1rem;
}
.client-box label {
  display: inline-table;
  padding: 0.02rem 0.06rem;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  line-height: 1;
  font-size: 0.12rem;
  color: #2b82f7;
}
.client-box p {
  line-height: 0.16rem;
  width: 2.7rem;
  margin-left: 0.08rem;
  font-size: 0.14rem;
  color: #737885;
}
.split-box {
  height: 0.4rem;
}
</style>
